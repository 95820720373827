@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: 'Inter', sans-serif;
    scroll-behavior: smooth;
  }

  body::-webkit-scrollbar,
  body ::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  body::-webkit-scrollbar-track,
  body ::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: none;
  }

  body::-webkit-scrollbar-thumb,
  body ::-webkit-scrollbar-thumb {
    background-color: #DBDBDE;
    border-radius: 10px;
    box-shadow: none;
  }

  body::-webkit-scrollbar-thumb:window-inactive,
  body ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(66, 66, 66, 0.4);
  }

  input,
  .select-btn-box,
  .select-input-box {
    @apply w-full py-2 px-4 outline-none rounded-[10px];
  }

  button {
    @apply 
      px-6 py-2
      text-sm capitalize 
      outline-none border-none rounded-[5px] 
      cursor-pointer disabled:cursor-not-allowed disabled:opacity-60
      transition-colors
    ;
  }

  a {
    @apply hover:text-primary-600;
  }

  
  input[type="radio"] {
    width: var(--size, 20px);
    height: var(--size, 20px);
    @apply 
      p-0 grid place-content-center appearance-none 
      border-2 border-[#323232] bg-white rounded-full
      transition-colors cursor-pointer
    ;
  }

  input[type="radio"]::before {
    content: "";
    width: 0.56em;
    height: 0.56em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    transform-origin: center;
    @apply bg-primary-600 rounded-full;
  }

  input[type="radio"]:checked::before {
    transform: scale(1);
  }
  
  input[type="checkbox"] {
    width: var(--size, 20px);
    height: var(--size, 20px);
    @apply 
      p-0 rounded-sm
      grid place-content-center appearance-none 
      border border-black-200 bg-white
      transition-colors cursor-pointer
    ;
  }

  input[type="checkbox"]:checked {
    @apply border-primary-600 bg-primary-600;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transition: 120ms transform ease-in-out;
    transform-origin: bottom left;
    background-color: white;
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }

  svg {
    @apply w-6 h-6 cursor-pointer;
  }
}

@layer components {
  /* display helpers */
  .df,
  .dfc,
  .dc {
    @apply flex gap-2;
  }

  .df, .dc {
    @apply items-center;
  }

  .dfc {
    @apply flex-col;
  }

  .dc {
    @apply justify-center;
  }

  .no-scroll-bar::-webkit-scrollbar {
    height: 0;
    width: 0;
  }

  .mini-scroll-bar::-webkit-scrollbar,
  .mini-scroll-bar ::-webkit-scrollbar {
    height: var(--scroll-bar-size, 5px);
    width: var(--scroll-bar-size, 5px);
  }


  /* background linear gradients */
  .bg-gr-1 {
   background: linear-gradient(180.82deg, #FF6F40 12.56%, #FFA447 90.49%);
  }

  .bg-gr-2 {
    background: linear-gradient(180deg, #FFA447 22.79%, #FFC635 100%);
  }
  
  .bg-gr-3 {
    background: linear-gradient(180.82deg, #FC2F6C 12.56%, #FFAB47 90.49%);
  }
  
  .bg-gr-4 {
    background: linear-gradient(180.82deg, #FF4079 12.56%, #FFAB47 90.49%);
  }
  
  .bg-gr-5 {
    background: linear-gradient(180.82deg, #004664 12.56%, #4098FF 90.49%);
  }


  /* buttons */
  .btn,
  .theme-btn {
    @apply h-10 md:h-[65px] text-ui-s sm:text-ui-m  disabled:cursor-not-allowed disabled:opacity-60;
  }

  .theme-btn {
    @apply font-medium;
    transition: background-position 0.5s;
    background: var(--bg, linear-gradient(to right,#ffc635 0%,#ffc635 50%,#ffffff 50%,#ffffff 100%));
    background-size: 201% 101%;
    background-position: 100% 0;
    color: var(--clr, #ff6f40);
  }

  .theme-btn-img {
    @apply w-3 sm:w-5 h-2.5 sm:h-[15px];
    transition: transform 0.4s ease-in-out;
  }

  .theme-btn:hover:enabled,
  .active-card-hover:hover .theme-btn {
    color: #fff;
    background-position: 0 0;
  }

  .theme-btn:hover:enabled .theme-btn-img {
    transform: translateX(70%);
    transition: transform 0.7s ease-out;
    filter: brightness(0) invert(1);
  }

  .secondary-btn .theme-btn-img {
    filter: invert(0) brightness(100);
  }

  .secondary-btn {
    --clr: #fff;
    --bg: linear-gradient(to right,#ffa447 0%,#ffa447 50%,#ff6f40 50%,#ff6f40 100%);
  }

  .teritary-btn {
    --clr: #ff6f40;
    --bg: linear-gradient(to right,#ff6f40 0%,#ff6f40 50%,#fff2ee 50%,#fff2ee 100%);
  }


  /* Modal */
  .modal-wrapper {
    @apply fixed inset-0 z-50;
  }

  .modal-overlay {
    @apply absolute inset-0 -z-1 bg-black-900 opacity-30;
  }

  .modal-content-wrapper {
    @apply h-screen flex items-center justify-center;
  }
  
  .modal-content {
    @apply p-6 sm:p-8 mx-4 bg-white shadow-xl rounded-2xl;
  }


  /* Select, DropDown */
  .select-options,
  .dropdown-options {
    @apply 
      p-1 z-50 bg-white 
      focus:outline-none
      divide-y divide-black-100
    ;
  }

  .select-options {
    @apply max-h-40 shadow-xl overflow-auto rounded-b-md;
  }

  .select-option-item,
  .dropdown-item {
    @apply px-4 py-2 cursor-pointer;
  }

  .select-option-item:first-child {
    @apply border-t border-black-100;
  }

  .select-arrow {
    @apply w-3 h-3 ml-auto pointer-events-none shrink-0 -rotate-90 transition-transform;
  }

  .select-btn-box.open .select-arrow,
  .select-input-box.open .select-arrow {
    @apply rotate-90;
  }

  .dropdown-options {
    @apply w-max shadow-[0_4px_15px_rgb(0,0,0,15%)] rounded-[10px];
  }

  .select-input {
    @apply p-0 bg-[inherit] rounded-none;
  }

  /* Accordion */
  .accordion-wrapper {
    @apply p-6 mb-4 border border-primary-600 transition-colors duration-300;    
  }

  .accordion-wrapper:where(.open) {
    @apply bg-primary-100;
  }

  .accordion-btn {
    @apply w-full p-0 text-lg font-bold text-left;
  }  

  .accordion-panel {
    @apply overflow-hidden transition-[height] duration-200;
  }


  /* Tab */
  .tab-list-wrapper {
    @apply gap-8 mb-10 border-b border-[#13131329];
  }
  
  .tab {
    @apply gap-3 py-3 px-8 text-xl text-black-300 relative;
  }

  .tab.selected {
    @apply text-black-900;
  }

  .tab svg {
    @apply w-[18px] h-[18px];
  }

  .selected svg {
    @apply stroke-black-900;
  }

  .tab span {
    @apply 
      absolute block -bottom-[3px] left-0 w-full h-[5px] 
      bg-primary-600 origin-center
    ;
    transform: scaleX(0);
    transition: all .3s ease-in-out;
  }
  
  .tab.selected span,
  .tab:hover span {
    transform: scaleX(1);
  }  

  /* Country select */
  .flag-btn {
    @apply 
      pl-4 pr-2 py-1
      focus:outline-none rounded-none
    ;
  }

  .flag-options {
    @apply 
      w-fit h-60 mt-2 text-sm overflow-auto bg-white z-1
      rounded-md shadow-md focus:outline-none
    ;
  }

  .flag-list {
    @apply cursor-pointer py-1.5 px-3;
  }

  /* loader */
  .loader {
    @apply 
      w-10 h-10 border-4 animate-spin 
      border-primary-100 border-t-primary-600 rounded-full
    ;
  }

  .pad-5 {
    padding: clamp(20px, 5%, 80px);
  }  

  .pad-6 {
    padding: clamp(20px, 6%, 96px);
  }  

  .back-to-home-btn {
    @apply 
      px-4 text-ui-m 
      text-primary-600 hover:bg-primary-100 
      transition-colors duration-300
    ;
  }
}


/* root constants */
:root {
  --toastify-icon-color-success: #5DC90A;
  --toastify-toast-min-height: 50px;
  --toastify-toast-width: 100vw;
}


/* Toastify Confiuration */
.Toastify__toast {
  @apply w-fit px-1 py-2 text-xl font-medium;
  margin: 0 auto .8rem;
}

.Toastify__toast-icon {
  margin-inline-end: 10px;
}

.Toastify__toast-icon svg {
  height: 28px;
  width: 28px;
}

.Toastify__toast-body {
  padding: 0 12px;
}

.Toastify__close-button {
  display: none;
}


.auth-box {
  box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.08);
  border-radius: 25px;
}

.auth-box input { 
  @apply py-2.5 px-4 text-sm rounded-md border border-black-200 placeholder:text-black-300;
}

.auth-box label {
  @apply block mb-2 text-sm font-bold;
}

.bg-clip {
  clip-path: polygon(100% 0, 100% 90%, 50% 100%, 0 90%, 0 0); 
}

.nav-links {
  @apply 
    flex-1 justify-around mdb:justify-end gap-4 lg:gap-6 bg-white
    max-mdb:fixed max-mdb:top-[74px] max-mdb:inset-x-0 
    max-mdb:shadow-[0_4px_4px_#0000001A] 
    max-mdb:flex-col max-mdb:py-8 
    lg:ml-auto
  ;
}

.technical-accordion-wrapper {
  @apply bg-white;
}

.technical-accordion-wrapper.open {
  @apply bg-primary-100;
}

.recommended-btn {
 --bg: linear-gradient(to right,#ff6f40 0%,#ff6f40 50%,#FFDDD1 50%,#FFDDD1 100%); 
}

:where(.terms-privacy) h1 {
  @apply mb-6 text-h-m md:text-h-l;
}

:where(.terms-privacy) h2 {
  @apply mb-2 text-h-s;
}

:where(.terms-privacy) p,
:where(.terms-privacy) li {
  @apply mb-5 text-ui-m lg:text-base text-black-400;
}

:where(.terms-privacy) li {
  @apply mb-3;
}

.selected-quest:has([type="radio"]:checked),
.selected-quest:has([type="checkbox"]:checked) {
  @apply bg-[#FFECE5];
}

.selected-quest-correct {
  background-color: #FFECE5;
}

.selected-quest-incorrect {
  background-color: #FFDDD1;
}

@media (max-width: 900px) {
  .bg-clip {
    clip-path: polygon(100% 0, 100% 95%, 50% 100%, 0 95%, 0 0); 
  }  
}
