.home-rocket-1 {
  animation: home-rocket-1 10s linear both infinite;
}

.home-cloud-1 {
  animation: home-cloud-1 10s linear both infinite;
}

.home-cloud-2 {
  animation: home-cloud-2 10s linear both infinite;
}

.home-prepare-black-box {
  animation: home-prepare-black-box 4s linear both infinite;
}

.home-prepare-brown-box {
  animation: home-cloud-2 4s linear both infinite;
}

.home-prepare-face {
  animation: home-prepare-face 4s linear both infinite;
}

.home-prepare-student {
  animation: home-prepare-student 4s linear both infinite;
}

.home-hand-1 {
  animation: home-hand-1 6s linear both infinite;
}

.home-hand-2 {
  animation: home-hand-2 6s linear both infinite;
}

@keyframes home-rocket-1 {
  0% {
    transform: translateX(0) translateY(0);
  }
  50% {
    transform: translateX(8%) translateY(-8%);
  }
  100% {
    transform: translateX(0) translateY(0);
  }
}

@keyframes home-cloud-1 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20%) translateY(-60%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes home-cloud-2 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes home-prepare-black-box {
  0% {
    transform: translateX(30%) translateY(0);
  }
  50% {
    transform: translateX(-40%) translateY(-20%);
  }
  100% {
    transform: translateX(30%) translateY(0);
  }
}

@keyframes home-prepare-face {
  0% {
    transform: translateX(0) translateY(-50%);
  }
  50% {
    transform: translateX(-8%) translateY(0);
  }
  100% {
    transform: translateX(0) translateY(-50%);
  }
}

@keyframes home-prepare-student {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-6%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes home-hand-1 {
  0% {
    transform: translateX(-40%) translateY(-50%);
  }
  50% {
    transform: translateX(-16%) translateY(-50%);
  }
  100% {
    transform: translateX(-40%) translateY(-50%);
  }
}

@keyframes home-hand-2 {
  0% {
    transform: translateX(40%) translateY(-50%);
  }
  50% {
    transform: translateX(16%) translateY(-50%);
  }
  100% {
    transform: translateX(40%) translateY(-50%);
  }
}
